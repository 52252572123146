import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby";
import * as Icon from 'react-feather';
import { getImageURL } from '../../format-date';
const GET_BLOG_SIDEBAR_DATA = graphql`
query GetBlogSidebarData {
    allStrapiBlogs(sort: {fields: created_at, order: DESC},limit:5) {
        nodes {
          title
          short_description
          blog_image {
            localFile{
                name
                extension
            }
          }
          blog_slug
          author {
            author_name
          }
          created_at
        }
      }
    allStrapiProducts(filter:{featured:{eq:true}}){
      nodes{
        product_name,
        slug,
        product_image{
            localFile{
                name
                extension
            }
        }
      }
    }
    allStrapiBlogTags{
        nodes{
            tag_name
        }
      }
    allStrapiBlogCategories{
      nodes{
        category_name
        category_slug
      }
    }
  }
  
`;
const BlogSidebar = () => (
    <StaticQuery query={GET_BLOG_SIDEBAR_DATA} render={sidebarData => {
        // sidebarData.allStrapiProducts.nodes.forEach(product => {
        //     product.srcset = "";
        //     product.sizes = "";
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 300)} 300w,`;
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 400)} 450w,`;
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 500)} 600w,`;
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 600)} 600w,`;
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 800)} 800w,`;
        //     product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 1000)} 1200w,`;
        //     product.sizes += `(max-width: 1200px) 40vw, 1200px`;
        // });
        // sidebarData.allStrapiBlogs.nodes.forEach(blog => {
        //     blog.srcset = "";
        //     blog.sizes = "";
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 300)} 300w,`;
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 400)} 450w,`;
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 500)} 600w,`;
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 600)} 600w,`;
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 800)} 800w,`;
        //     blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 1000)} 1200w,`;
        //     product.sizes += `(max-width: 1200px) 40vw, 1200px`;
        // });
        return (
            <div className="widget-area is-sticky" id="secondary">
                <div className="widget widget_startp_posts_thumb">
                    <h3 className="widget-title">Featured Products</h3>

                    {sidebarData.allStrapiProducts.nodes.map((product, p) => (
                        <article className="item" key={product.slug}>
                            <Link to={`/products/${product.slug}/`} className="thumb">
                                {/* <span className="fullimage cover" style={{ "backgroundImage": `url(${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension)}` }} role="img"></span> */}
                                <img src={getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 200)} role="img" />
                            </Link>
                            <div className="info">
                                <time>{product.release_date}</time>
                                <h4 className="title usmall">
                                    <Link to={`/products/${product.slug}/`}>
                                        {product.product_name}
                                    </Link>
                                </h4>
                            </div>

                            <div className="clear"></div>
                        </article>
                    ))}
                </div>
                <div className="widget widget_startp_posts_thumb">
                    <h3 className="widget-title">Recent Blog Posts</h3>
                    {sidebarData.allStrapiBlogs.nodes.map((blog, b) => (
                        <article className="item" key={blog.blog_slug + blog.blog_image.url}>
                            <Link to={`/${blog.blog_slug}/`} className="thumb">
                                <img src={getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 200)} role="img" />
                                {/* <span style={{ "backgroundImage": `url(${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension)})` }} className="fullimage cover" role="img"></span> */}
                            </Link>
                            <div className="info">
                                <time>{blog.createdAt}</time>
                                <h4 className="title usmall">
                                    <Link to={`/${blog.blog_slug}/`}>
                                        {blog.title}
                                    </Link>
                                </h4>
                            </div>

                            <div className="clear"></div>
                        </article>
                    ))}
                </div>
                <div className="widget widget_tag_cloud">
                    <h3 className="widget-title">Blog Categories</h3>
                    <div className="tagcloud">
                        {sidebarData.allStrapiBlogCategories.nodes.map((category, c) => (
                            <Link to={`/blog/category/${category.category_slug}/`} key={category.category_slug}>{category.category_name}</Link>
                        ))}
                    </div>
                </div>
                <div className="widget widget_tag_cloud">
                    <h3 className="widget-title">Blog Tags</h3>
                    <div className="tagcloud">
                        {sidebarData.allStrapiBlogTags.nodes.map((tag, t) => (
                            <Link key={tag.tag_name}>{tag.tag_name}</Link>
                        ))}
                    </div>
                </div>
            </div>
        )
    }}></StaticQuery>
);
export default BlogSidebar;  