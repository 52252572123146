import React from "react";
import ReactMarkdown from 'react-markdown';
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import * as Icon from 'react-feather';
import { Link, graphql } from "gatsby";
import BlogSidebar from '../components/Blog/BlogSidebar';
import { formatDate, getImageURL } from "../format-date";
const BlogDetails = ({ data }) => {
    const blogDetails = data.strapiBlogs;
    const blogCategories = data.allStrapiBlogCategories.nodes;
    let blogTags = [];
    blogDetails.blog_tags.forEach(tag => {
        blogTags.push(tag.tag_name);
    });
    blogDetails.srcset = "";
    blogDetails.sizes = "";
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 300)} 300w,`;
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 450)} 450w,`;
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 500)} 600w,`;
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 600)} 600w,`;
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 800)} 800w,`;
    blogDetails.srcset += `${getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension, 1200)} 1200w,`;
    blogDetails.sizes += `(max-width: 1200px) 40vw, 1200px`;
    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        );
    }
    function addImageLazyLoading(props){
        return (
          <img srcSet={props.src} alt={props.alt} loading="lazy"></img>
        )
    }
    return (
        <Layout>
            <SEO title={blogDetails.seo_title} description={blogDetails.seo_description} blogTags={blogTags} type="article" blogDetails={blogDetails} image={getImageURL(blogDetails.seo_image.localFile.name, blogDetails.seo_image.localFile.extension)} url={`/${blogDetails.blog_slug}/`} />
            <NavbarStyleThree />
            {/* <PageBanner pageTitle="Blog Details" /> */}
            <div className="blog-details-area ptb-80 pt-210">
                <div className="container-fluid padd-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img src={getImageURL(blogDetails.blog_image.localFile.name, blogDetails.blog_image.localFile.extension)} srcSet={blogDetails.srcset} sizes={blogDetails.sizes} style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }} alt={blogDetails.title} />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock /> {formatDate(blogDetails.created_at, 'MMMM DD YYYY')}
                                            </li>
                                            <li>
                                                <Icon.User /> <Link>{blogDetails.author.author_name}</Link>
                                            </li>
                                        </ul>
                                        <h3>{blogDetails.title}</h3>
                                    </div>
                                    <ReactMarkdown components={{ a: LinkRenderer,img:addImageLazyLoading }} children={blogDetails.description} />
                                    {/* <p style={{ "whiteSpace": "pre-wrap" }} dangerouslySetInnerHTML={{ __html: blogDetails.description }}></p> */}
                                    {/* <h2>The security risks of changing package owners</h2>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <blockquote>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                                    <cite>Tom Cruise</cite>
                                </blockquote>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <ul className="block-gallery columns-3">
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg7} alt="image" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg9} alt="image" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg8} alt="image" />
                                        </figure>
                                    </li>
                                </ul>

                                <h3>Four major elements that we offer:</h3>

                                <ul className="features-list">
                                    <li><Icon.Check /> Scientific Skills For getting a better result</li>
                                    <li><Icon.Check /> Communication Skills to getting in touch</li>
                                    <li><Icon.Check /> A Career Overview opportunity Available</li>
                                    <li><Icon.Check /> A good Work Environment For work</li>
                                </ul>

                                <h3>Setting the mood with incense</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <h3>The Rise Of Smarketing And Why You Need It</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p> */}
                                </div>

                                <div className="article-footer">
                                    <div className="article-tags">
                                        {blogCategories.map((category, c) => (
                                            <Link to={`/blog/category/${category.category_slug}`} key={c + category.category_slug + category.category_name}>{category.category_name}</Link>
                                        ))}
                                    </div>
                                </div>

                                {/* <div className="startp-post-navigation">
                                    <div className="prev-link-wrapper">
                                        <div className="info-prev-link-wrapper">
                                            <Link to="#">
                                                <span className="image-prev">
                                                    <img src={BlogImg2} alt="image" />
                                                    <span className="post-nav-title">Prev</span>
                                                </span>

                                                <span className="prev-link-info-wrapper">
                                                    <span className="prev-title">Don't buy a tech gift until you read these rules</span>
                                                    <span className="meta-wrapper">
                                                        <span className="date-post">January 21, 2021</span>
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="next-link-wrapper">
                                        <div className="info-next-link-wrapper">
                                            <Link to="#">
                                                <span className="next-link-info-wrapper">
                                                    <span className="next-title">The golden rule of buying a phone as a gift</span>
                                                    <span className="meta-wrapper">
                                                        <span className="date-post">January 21, 2021</span>
                                                    </span>
                                                </span>

                                                <span className="image-next">
                                                    <img src={BlogImg3} alt="image" />
                                                    <span className="post-nav-title">Next</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="comments-area">
                                    <h3 className="comments-title">2 Comments:</h3>

                                    <ol className="comment-list">
                                        <li className="comment">
                                            <article className="comment-body">
                                                <footer className="comment-meta">
                                                    <div className="comment-author vcard">
                                                        <img src={ClientImg1} className="avatar" alt="image" />
                                                        <b className="fn">John Jones</b>
                                                        <span className="says">says:</span>
                                                    </div>

                                                    <div className="comment-metadata">
                                                        April 24, 2019 at 10:59 am
                                                    </div>
                                                </footer>

                                                <div className="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>

                                                <div className="reply">
                                                    <a to="#" className="comment-reply-link">Reply</a>
                                                </div>
                                            </article>

                                            <ol className="children">
                                                <li className="comment">
                                                    <article className="comment-body">
                                                        <footer className="comment-meta">
                                                            <div className="comment-author vcard">
                                                                <img src={ClientImg2} className="avatar" alt="image" />
                                                                <b className="fn">Steven Smith</b>
                                                                <span className="says">says:</span>
                                                            </div>

                                                            <div className="comment-metadata">
                                                                April 24, 2019 at 10:59 am
                                                            </div>
                                                        </footer>

                                                        <div className="comment-content">
                                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                        </div>

                                                        <div className="reply">
                                                            <Link to="#" className="comment-reply-link">Reply</Link>
                                                        </div>
                                                    </article>
                                                </li>

                                                <ol className="children">
                                                    <li className="comment">
                                                        <article className="comment-body">
                                                            <footer className="comment-meta">
                                                                <div className="comment-author vcard">
                                                                    <img src={ClientImg3} className="avatar" alt="image" />
                                                                    <b className="fn">Sarah Taylor</b>
                                                                    <span className="says">says:</span>
                                                                </div>

                                                                <div className="comment-metadata">
                                                                    April 24, 2019 at 10:59 am
                                                                </div>
                                                            </footer>

                                                            <div className="comment-content">
                                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                            </div>

                                                            <div className="reply">
                                                                <Link to="#" className="comment-reply-link">Reply</Link>
                                                            </div>
                                                        </article>
                                                    </li>
                                                </ol>
                                            </ol>
                                        </li>

                                        <li className="comment">
                                            <article className="comment-body">
                                                <footer className="comment-meta">
                                                    <div className="comment-author vcard">
                                                        <img src={ClientImg4} className="avatar" alt="image" />
                                                        <b className="fn">John Doe</b>
                                                        <span className="says">says:</span>
                                                    </div>

                                                    <div className="comment-metadata">
                                                        April 24, 2019 at 10:59 am
                                                    </div>
                                                </footer>

                                                <div className="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>

                                                <div className="reply">
                                                    <Link to="#" className="comment-reply-link">Reply</Link>
                                                </div>
                                            </article>

                                            <ol className="children">
                                                <li className="comment">
                                                    <article className="comment-body">
                                                        <footer className="comment-meta">
                                                            <div className="comment-author vcard">
                                                                <img src={ClientImg5} className="avatar" alt="image" />
                                                                <b className="fn">James Anderson</b>
                                                                <span className="says">says:</span>
                                                            </div>

                                                            <div className="comment-metadata">
                                                                April 24, 2019 at 10:59 am
                                                            </div>
                                                        </footer>

                                                        <div className="comment-content">
                                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                        </div>

                                                        <div className="reply">
                                                            <Link to="#" className="comment-reply-link">Reply</Link>
                                                        </div>
                                                    </article>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>

                                    <div className="comment-respond">
                                        <h3 className="comment-reply-title">Leave a Reply</h3>

                                        <form className="comment-form">
                                            <p className="comment-notes">
                                                <span id="email-notes">Your email address will not be published.</span>
                                                Required fields are marked
                                                <span className="required">*</span>
                                            </p>
                                            <p className="comment-form-comment">
                                                <label>Comment</label>
                                                <textarea
                                                    name="comment"
                                                    id="comment"
                                                    cols="45"
                                                    rows="5"
                                                    maxLength="65525"
                                                    required="required"
                                                ></textarea>
                                            </p>
                                            <p className="comment-form-author">
                                                <label>Name <span className="required">*</span></label>
                                                <input
                                                    type="text"
                                                    id="author"
                                                    name="author"
                                                    required="required"
                                                />
                                            </p>
                                            <p className="comment-form-email">
                                                <label>Email <span className="required">*</span></label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    required="required"
                                                />
                                            </p>
                                            <p className="comment-form-url">
                                                <label>Website</label>
                                                <input
                                                    type="url"
                                                    id="url"
                                                    name="url"
                                                />
                                            </p>
                                            <p className="comment-form-cookies-consent">
                                                <input
                                                    type="checkbox"
                                                    value="yes"
                                                    name="comment-cookies-consent"
                                                    id="comment-cookies-consent"
                                                />
                                                <label htmlFor="comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </p>
                                            <p className="form-submit">
                                                <input
                                                    type="submit"
                                                    name="submit"
                                                    id="submit"
                                                    className="submit"
                                                    value="Post Comment"
                                                />
                                            </p>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="card p-3">
                                <BlogSidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    )
}

export default BlogDetails;
export const query = graphql`
query GetBlogDetails($blog_slug: String) {
    strapiBlogs(blog_slug: {eq: $blog_slug}) {
      title
      author {
        author_name
        author_profile_link
        author_profile_picture{
            localFile{
                name
                extension
            }
        }
      }
      blog_categories {
        category_name
        category_slug
      }
      blog_image {
        localFile{
            name
            extension
            }
      }
      blog_slug
      description
      last_updated
      short_description
      created_at
      seo_title
      seo_image{
        localFile{
          name
          extension
          }
      }
      seo_description
      published_at
      updated_at
      blog_tags{
        tag_name
      }
    }
    allStrapiProducts(filter:{featured:{eq:true}}){
        nodes{
          product_name,
          slug,
          product_image{
          url
          }
        }
      }
      allStrapiProductTags{
        nodes{
          tag_name
        }
      }
      allStrapiBlogCategories{
        nodes{
          category_name
          category_slug
        }
      }
  }
  
`